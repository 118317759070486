<template>
    <div>
        <div>
            <!-- <van-nav-bar title="新增/修改 巡检" left-text="返回" left-arrow @click-left="$router.go(-1)" /> -->
            <van-divider :style="{ color: '#1989fa', borderColor: '#1989fa', padding: '0 16px' }"><van-tag
                    type="primary" size="large">{{model.TYPE_NAME}}</van-tag></van-divider>
            <van-grid :column-num="2">
                <van-grid-item>项目</van-grid-item>
                <van-grid-item>情况</van-grid-item>
            </van-grid>
            <van-grid direction="horizontal" :column-num="2" v-for="(item, index) in itemList" :key="index">
                <van-grid-item>
                    <span>{{item.NAME}}</span>
                </van-grid-item>
                <van-grid-item>
                    <van-radio-group v-model="item.STATUS" direction="horizontal" @change="ztChange">
                        <van-radio :name="0">合格</van-radio>
                        <van-radio :name="1">整改</van-radio>
                    </van-radio-group>
                </van-grid-item>
            </van-grid>


            <van-field v-model="model.LOCATION" rows="1" label="检查位置" autosize type="textarea" placeholder="请输入检查位置" />
            <van-field v-model="model.DATE" readonly label="检查日期" @click="date.show = true" placeholder="请选择检查日期"
                is-link />
            <van-calendar v-model="date.show" @select="rqConfirm" :show-confirm="false" :min-date="date.minDate"
                :max-date="date.maxDate" />

            <template v-if="iszg">
                <van-field v-model="model.PROBLEM" rows="1" label="存在问题" autosize type="textarea"
                    placeholder="请输入存在问题" />
                <van-field v-model="model.RECTIFY_NAME" readonly label="整改人" placeholder="请选择整改人" is-link
                    @click="showPicker = true" />
                <van-popup v-model="showPicker" round position="bottom">
                    <van-search v-model="zgrName" placeholder="请输入整改人" shape="round" @input="getZgr" />
                    <van-picker show-toolbar :columns="teachers" @confirm="lsConfirm" @cancel="showPicker = false"
                        value-key="Name" />
                </van-popup>
            </template>
            <div style="margin: 15px">
                <template v-if="candel">
                    <van-button type="danger" size="large" icon="success" block @click="delData" :loading="ret.loading"
                        loading-text="正在删除。。。">删除巡检</van-button>
                    <br /></template>
                <van-button type="primary" size="large" icon="success" block @click="submit" :loading="ret.loading"
                    loading-text="正在提交。。。">确认保存</van-button>
                <br />
                <van-button plain type="default" size="large" icon="cross" block
                    @click="$router.go(-1)">取消返回</van-button>
            </div>
        </div>
    </div>
</template>

<script>
    import { Dialog } from 'vant';
    import moment from "moment";
    export default {
        name: "new",
        data() {
            return {
                ret: {
                    loading: false,
                    finish: false,
                },
                model: {},
                typeId: 0,
                itemList: [],
                date: {
                    show: false,
                    minDate: moment().add(-1, 'y').toDate(),
                    maxDate: moment().toDate(),
                },
                showPicker: false,
                teachers: [],
                allTeachers: [],
                iszg: false,

                candel: false,
                zgrName: ''
            }
        },
        created() {
            this.getTeacher();
            let query = this.$route.query
            if (query.tid > 0) {
                this.itemList = JSON.parse(query.items)
                this.model.TYPE_ID = query.tid
                this.model.TYPE_NAME = query.tname
            } else {
                this.model = JSON.parse(query.data)
                this.itemList = this.model.ITEM_LIST
                this.ztChange()
            }
            if (this.model.ID && this.model.ID > 0) {
                this.canDelPower()
            }

            if (this.itemList.length == 0) {
                Dialog.alert({
                    message: '该类型没有项目',
                }).then(() => {
                    this.$router.go(-1)
                });
            }
        },
        methods: {
            submit() {
                let self = this;
                // console.log(this.itemList)
                self.model.CONTENT_JSON = JSON.stringify(self.itemList)
                if (!self.iszg) {
                    self.model.RECTIFY_ID = 0
                    self.model.PROBLEM = ""
                }
                // console.log(self.model)
                self.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/SaveRecord",
                    data: self.model,
                    finally() {
                        self.ret.loading = false;
                    },
                    completed() {
                        self.$dialog.alert({
                            message: "更改成功",
                            confirmButtonText: "确定",
                        }).then(() => {
                            self.$router.go(-1);
                        })
                    }
                })
            },
            rqConfirm(val) {
                this.date.show = false;
                this.model.DATE = moment(val).format('YYYY-MM-DD');
            },
            lsConfirm(val) {
                this.model.RECTIFY_ID = val.Id;
                this.model.RECTIFY_NAME = val.Name;
                this.showPicker = false;
            },
            getTeacher() {
                let self = this;
                self.whale.remote.getResult({
                    url: "/api/Mobile/ORG/TeacherApi/GetTeachers",
                    completed: function (res) {
                        self.teachers = res.DATA
                        self.allTeachers = res.DATA
                    }
                })
            },
            ztChange() {
                this.iszg = this.itemList.filter(x => x.STATUS == 1).length > 0 ? true : false;
            },

            canDelPower() {
                let self = this;
                self.whale.remote.getResult({
                    url: "/api/Mobile/INS/RecordApi/GetDelPower",
                    data: { ID: this.model.ID },
                    completed: function (res) {
                        self.candel = res.DATA;
                    }
                })
            },
            delData() {
                let self = this;
                self.$dialog.confirm({
                    title: "删除巡检",
                    message: "是否需要删除记录",
                    theme: "round",
                    confirmButtonText: "确认",
                    cancelButtonText: "取消"
                }).then(() => {
                    console.log("确认")
                    self.whale.remote.getResult({
                        url: "/api/Mobile/INS/RecordApi/Delete",
                        data: { ID: this.model.ID },
                        completed: function (res) {
                            if (res.DATA) {
                                self.$router.go(-1);
                            }
                        }
                    })
                }).catch(() => {
                    console.log("取消")
                })
            },
            getZgr(val) {
                if (val != '') {
                    this.teachers = this.allTeachers.filter(x => x.Name.includes(val))
                } else {
                    this.teachers = this.allTeachers
                }
            }
        }
    }
</script>